import React from "react"
import { useCountdown } from "hooks/useCountdown"
import "./index.css"
import Marquee from "react-marquee"

const formatDuration = (duration) => {
  return duration >= 10
    ? `${duration}`
    : `0${duration}`
}

const timeHours = (milliseconds) => {
  return  Math.floor((
    milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
}

const timeMinutes = (milliseconds) => {
  return Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
}

const timeSeconds = (milliseconds) => {
  return Math.floor((milliseconds % (1000 * 60)) / 1000)
}

export const SimpleClock = () => {
  const endDate = new Date('January 1, 2020')
  const timeRemaining = useCountdown(endDate, 500)
  const hoursLeft = timeHours(timeRemaining);
  const minutesLeft = timeMinutes(timeRemaining);
  const secondsLeft = timeSeconds(timeRemaining)


  return (
    <div className="clock-background">
      <div className="clock-display">
        <div className="clock-wrapper">
          <span className="clock-time">
            {formatDuration(hoursLeft)}
          </span>
          <span className="clock-spacer">
            :
          </span>
          <span className="clock-time">
            {formatDuration(minutesLeft)}
          </span>
          <span className="clock-spacer">
            :
          </span>
          <span className="clock-time">
            {formatDuration(secondsLeft)}
          </span>
        </div>
        <div className="catch-phrase">
          <Marquee text=" • THE END IS HERE •"/>
          <div>SERIOUSLY GET OUT</div>
        </div>
      </div>
    </div>
  )
}