import React, { useEffect, useState } from "react"

export const useCountdown = (endTime, interval) => {
  const [
    timeRemaining,
    setTimeRemaining
  ] = useState(endTime - new Date().getTime())

  useEffect(() => {
    const intervalId = window.setInterval(
      () => {
        const timeNow = new Date().getTime()
        const safeTimeRemaining = Math.max(endTime - timeNow, 0)
        setTimeRemaining(safeTimeRemaining)
      },
      interval
    )

    return () => {
      window.clearInterval(intervalId)
    }
  })

  return timeRemaining
}